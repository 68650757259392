<template>
  <div class="wy-list-page">

<!--    <floating-button :route="{ name: 'Invoice' }"/>-->
    <CCard>
      <CCardBody>

        <CAlert color="danger" v-if="!_.isEmpty(error)" closeButton @update:show="error = ''">
          {{ error }}
        </CAlert>

        <CAlert color="success text-center" v-if="!_.isEmpty(message)">
          {{ message }}
        </CAlert>

        <div v-if="!loaded">
          <inline-loader/>
        </div>

        <div v-if="loaded">
          <div class="wy-search-input-container" v-if="_.isEmpty(message)">
            <input
                class="form-control full-width"
                :placeholder="$t('type_here_to_search')"
                type="text"
                v-model="search"
                @input="runSearch"
                @keydown.esc="clearSearch"
            />
            <button class="wy-clear-button" @click="clearSearch" v-if="search !== ''">
              <span class="wy-icon-clear-input"></span>
            </button>
          </div>

          <CAlert color="success text-center" class="mt-3" v-if="noSearchResult">
            {{ $t('no_result_found') }}
          </CAlert>

          <div v-if="!_.isEmpty(invoices)" class="table-responsive">
            <table class="table table-hover wy-list-table table-bordered">
              <thead>
              <tr>
                <th style="width: 50px;">#</th>
                <th>{{ $t('the_customer') }}</th>
                <th>{{ $t('worker') }}</th>
                <th class="no-wrap">{{ $t('gas_station') }}</th>
                <th>{{ $t('product') }}</th>
                <th>{{ $t('quantity') }}</th>
                <th class="no-wrap">{{ $t('liter_price_without_vat') }}</th>
                <th class="no-wrap">{{ $t('liter_price_with_vat') }}</th>
                <th class="no-wrap">{{ $t('total_without_vat') }}</th>
                <th class="no-wrap">{{ $t('total_with_vat') }}</th>
                <th class="no-wrap">{{ $t('date') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(invoice, invoiceIndex) in invoices"
                  :key="`invoice_${invoiceIndex}`" style="font-size: 12px">
                <td>{{ invoice.id }}</td>
                <td>
                  <div v-if="invoice.customer">
                    <strong class="no-wrap">{{ invoice.customer.full_name }}</strong>
                  </div>
                  <div v-else class="font-italic no-wrap">{{ $t('undefined') }}</div>

                  <div class="text-muted" v-if="invoice.short_name">{{ invoice.customer.short_name }}</div>
                </td>
                <td>
                  <strong>{{ invoice.worker.name }}</strong>
                  <div class="text-muted">{{ invoice.worker.email }}</div>
                </td>
                <td class="text-center">
                  {{ invoice.gas_station.name }}
                </td>
                <td>
                  <div class="no-wrap text-center">
                    {{ invoice.products[0].product_name }}
                  </div>
                </td>
                <td>
                  <div class="no-wrap text-center">
                    {{ invoice.products[0].quantity | floatNumber }} {{ $t('liters') }}
                  </div>
                </td>
                <td>
                  <div class="text-muted no-wrap text-center">
                    {{ invoice.products[0].product_consumer_price }}
                  </div>
                </td>
                <td>
                  <div class="text-bold no-wrap text-center">
                    {{ (Number(invoice.products[0].product_consumer_price_with_vat)).toFixed(2) }}
                  </div>
                </td>
                <td>
                  <div class="text-muted no-wrap text-center">
                    {{ invoice.total }}
                  </div>
                </td>
                <td>
                  <div class="text-bold no-wrap text-center">
                    {{ invoice.total_with_vat }}
                  </div>
                </td>
                <td class="no-wrap">
                  {{ formattedDate(invoice.created_at) }}
                </td>
              </tr>
              </tbody>
            </table>

            <div class="row wy-pagination-info">
              <div class="col">
                {{ $t('from') }} {{ from }}
                {{ $t('to') }} {{ to }}
              </div>
              <div class="col text-right">
                <strong>{{ $t('total') }}: {{ total }}</strong>
              </div>
            </div>

            <CPagination
                align="center"
                :pages="lastPage"
                :active-page.sync="currentPage"
            />
          </div>
        </div>

      </CCardBody>
    </CCard>

  </div>
</template>

<script>
import { invoices } from "../../../api/invoice";
import FloatingButton from "../../components/FloatingButton";
import InlineLoader from "../../components/InlineLoader";
import _ from "lodash";
import moment from "moment";

export default {
  name: 'InvoicesList',
  components: {
    FloatingButton,
    InlineLoader,
  },
  data() {
    return {
      currentPage: 0,
      activePage: 0,
      firstPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      from: 0,
      to: 0,
      message: '',
      error: '',
      search: '',
      loaded: false,
      noSearchResult: false,
      //
      invoices: [],
    };
  },
  watch: {
    currentPage(newValue) {
      this.getInvoices();
      this.$router.replace({name: this.$route.name, params: this.$route.params, query: {page: newValue}});
    },
    '$route.query.page': function (page) {
      if (this._.isUndefined(page)) {
        page = 1;
      }
      this.error = '';
      this.currentPage = page;
    }
  },
  mounted() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.getInvoices();
    this.firstPage = 1;
  },
  methods: {
    getInvoices() {
      this.noSearchResult = false;
      invoices(this, this.currentPage, this.search, (response) => {
        this.invoices = response.invoices.data;
        this.lastPage = Number(response.invoices.last_page);
        this.perPage = Number(response.invoices.per_page);
        this.total = Number(response.invoices.total);
        this.from = Number(response.invoices.from);
        this.to = Number(response.invoices.to);
        this.loaded = true;
        if (this._.isEmpty(this.invoices)) {
          if (!this._.isEmpty(this.search)) {
            this.noSearchResult = true;
          } else {
            this.message = this.$t('empty_list');
          }
        }
        this.$forceUpdate();
      });
    },
    clearSearch() {
      if (this.search !== '') {
        this.search = '';
        this.getInvoices();
      }
    },
    runSearch: _.debounce(function() {
      this.currentPage = 1;
      this.getInvoices();
    }, 500),
    englishNumbers(number) {
      const ar = '٠١٢٣٤٥٦٧٨٩'.split('');
      const en = '0123456789'.split('');
      return number.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x) => en[ar.indexOf(x)]);
    },
    formattedDate(date) {
      return this.englishNumbers(moment(date).locale('ar').format('dddd DD MMM YYYY - hh:mmA'));
    },
  },
  filters: {
    floatNumber(text) {
      return parseFloat(text);
    }
  }
}
</script>

<style lang="scss" scoped>
.wy-list-page {
  .wy-search-input-container::v-deep {
    position: relative;
    input {
      background-color: #F1F1F1;
      [dir=ltr] & {
        padding-right: 40px;
      }

      [dir=rtl] & {
        padding-left: 40px;
      }
    }
    button.wy-clear-button {
      position: absolute;
      background: none;
      border: none;
      top: 8px;
      color: #C41117;
      transition: color 350ms ease;
      [dir=ltr] & {
        right: 5px;
      }

      [dir=rtl] & {
        left: 5px;
      }
      span {
        margin: 0!important;
        font-size: 24px;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        color: #222222;
      }
    }
  }
  .wy-list-table {
    thead {
      th {
        border-top-width: 0;
      }
    }
  }
}
</style>
