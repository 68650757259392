import axios from "./_base";
import errorHandler from "./_errorHandler";

export const invoices = (vue, page, search, callback) => {
    search = search.trim();
    let url = `http://192.168.43.96/api/invoices?page=${page}`;
    if (search !== '') {
        url = `${url}&search=${search}`
    }

    axios.get(url, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error)
    });
};
